<template>
  <div class="row no-gutters">
    <form class="col-12 order-code">
      <div class="h3 mb-3">Зарегистрировать продукт</div>
      <div class="alert alert-success my-2" v-if="success">
        {{ add_text }}
      </div>
      <div class="input-group border-0 add-tube" v-bind:class="{ error: this.error }">
        <div class="invalid-feedback d-block my-2" v-if="error">
          Введите код с упаковки
        </div>

        <input
            ref="input"
            class="form-control ps-3"
            v-model="state.code"
            type="text"
            name="tube"
            @input="inputHandler"
            placeholder="Введите код на упаковке"
        />
        <button
            :disabled="disabled"
            class="btn btn-blue"
            @click.prevent="addProductCode()"
        > Зарегистрировать
        </button>
      </div>
    </form>
  </div>

</template>

<script>

//import axios from "axios";

import useValidate from '@vuelidate/core'
import {required, minLength, maxLength} from '@vuelidate/validators'
import {computed, reactive} from "vue";

export default {
  name: "FormOrderTube",
  components: {},
  setup() {
    const state = reactive({
      code: ""
    })
    const rules = computed(() => {
      return {
        code: {required, minLength: minLength(4), maxLength:maxLength(10)}
      }
    })
    const v$ = useValidate(rules, state)
    return {state, v$}
  },
  mounted() {},
  computed: {},
  props: {},
  methods: {
    inputHandler() {
      let value = this.$refs.input.value;
      value = value.replace(/\D/g,'').substr(0,10)
      this.$refs.input.value = value;
      this.state.code = this.$refs.input.value
      this.v$.$validate()
      this.disabled = this.v$.$error
    },
    addProductCode() {

      this.v$.$validate()
      this.error = this.v$.$error
      if (!this.error) {
        //TODO
        this.$store
            .dispatch('addTube', {code: this.state.code})
            .then((resp) => {

              if (resp.data.errors) {
                this.error = true
                this.success = false
                this.$refs.input.value = '';
                this.state.code = ""
                setTimeout(() => this.error = false, 1000)

              } else {
                this.add_text = this.state.code + ' :: ' + this.valid_text
                this.state.code = ""
                this.success = true
                setTimeout(() => this.success = false, 2000)
              }

            })


        // this.$store
        //     .dispatch('addTube', this.state.code)
        //     .then((e) => {
        //       if (e[0].added) {
        //         this.add_text = this.state.code+' :: '+this.add_text
        //         this.success = true
        //         this.state.code = ""
        //       }
        //     })

      }
    }

  },
  data() {
    return {
      disabled:true,
      error: false,
      success: false,
      invalid_text: "Продукт не найден",
      valid_text: "Продукт зарегистрирован",
      add_text: ""
    };
  }
}
</script>
<style>
.btn-blue {

  border: 1px solid #4AC0DF!important;
}
.btn-blue:disabled {
  border: solid 1px #000000 !important;
  background-color: #ffffff
}

.btn-blue:hover {
  border: solid 1px #000000 !important;
}

</style>
