//import router from "../../routers";

const mutationsPersonal = {
    loadUserData(state, payload) {
        state.PERSONAL = payload
    },
    setPersonalUpdated(state, payload) {
        state.PERSONAL.updated = payload
    },
    setAppId(state, payload) {
        state.PERSONAL.APP_ID = payload
    },
    setOrderList(state, payload) {

        state.PERSONAL.LAST_ORDER = payload[0]
        state.PERSONAL.ORDERS_LIST = payload
    },
    setTempToken(state, payload) {
        state.accessTempToken = payload
    }


    // auth_success(state, token, user) {
    //     state.status = 'success'
    //     state.token = token
    //     state.user = user
    //     router.push('/personal').then();
    // },
    // logout(state) {
    //     state.status = ''
    //     state.token = ''
    //     router.push('/auth').then(r => {
    //         console.log(r)
    //     })
    // }
}


export default mutationsPersonal
