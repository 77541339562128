<template>
  <div class="card-profile">
    <div class="mb-5 mt-3 text-end lk-menu">
      <a href="#">
        <svg width="4" height="20" viewBox="0 0 4 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M2 4C3.10457 4 4 3.10457 4 2C4 0.895431 3.10457 0 2 0C0.895431 0 0 0.895431 0 2C0 3.10457 0.895431 4 2 4Z"
              fill="white"/>
          <path
              d="M2 12C3.10457 12 4 11.1046 4 10C4 8.89543 3.10457 8 2 8C0.895431 8 0 8.89543 0 10C0 11.1046 0.895431 12 2 12Z"
              fill="white"/>
          <path
              d="M2 20C3.10457 20 4 19.1046 4 18C4 16.8954 3.10457 16 2 16C0.895431 16 0 16.8954 0 18C0 19.1046 0.895431 20 2 20Z"
              fill="white"/>
        </svg>
      </a>
    </div>
    <div class="body-profile">
      <div class="mb-5 img-profile d-none">
        <div class="mx-auto avatar ">
          <img src="/img/avatar.png" alt="avatar.png">
        </div>
      </div>
      <div class="form-edit-profile" v-if="modeEdit">
        <div class="data-profile">
          <div class="element-profile">
            <InputGroup
                label="Имя"
                name="name"
                type="text"
                :placeholder="name"
                invalid_text="Укажите Имя"
                :view_actions="false"
                @event="inputHandler"
            />
          </div>
          <div class="element-profile">
            <InputGroup
                label="Фамилия"
                name="surname"
                type="text"
                :placeholder="surname"
                invalid_text="Укажите Фамилию"
                :view_actions="false"
                @event="inputHandler"
            />
          </div>
          <div class="element-profile">

            <div class="input-group mb-1 input-group-cyan input-group-radio" style="opacity:0.6">
              <label class="form-label mt-3"><span>Пол</span></label>
              <div class="form-check" v-if="gender === 'M'">
                <label class="form-check-label" for="genderM" >
                  Мужской
                  <input class="form-check-input"
                         value="М"
                         type="radio"
                         name="gender"
                         id="genderM"
                         checked
                  >
                </label>
              </div>
              <div class="form-check" v-if="gender === 'F'">
                <label class="form-check-label" for="genderF">
                  Женский
                  <input class="form-check-input"
                         value="F"
                         type="radio"
                         name="gender"
                         id="genderF"
                         checked
                  >
                </label>
              </div>
            </div>
          </div>

        </div>
        <div class="data-profile">
          <div class="element-profile">
            <InputGroup
                :mask="'+7 (###) ##-##-##'"
                label="Телефон"
                name="phone"
                type="tel"
                :default_value="phone"
                :placeholder="phone"
                invalid_text="Укажите телефон"
                :view_actions="true"
                @event="inputHandler"
            />
          </div>
          <div class="element-profile">
            <InputGroup
                label="Электронная почта"
                name="phone"
                type="email"
                :placeholder="email"
                invalid_text="Укажите почту"
                :view_actions="false"
                @event="inputHandler"
            />
          </div>
        </div>

        <div class="d-flex btn-group"
             role="group"
             aria-label="Изменение профиля"
        >
          <button
              @click.prevent="update"
              class="btn btn-blue btn-profile"
              :disabled="editDisabled"
          >
            Сохранить
          </button>
          <button
              v-if="modeEdit"
              @click.prevent="modeEdit = false"
              class="btn btn-blue-inverse btn-profile">
            Отменить
          </button>
        </div>
      </div>
      <div v-else>
        <div class="name-profile">
          <b>{{ surname }} {{ name }}</b>
          <p>Добро пожаловать, {{ name }}!</p>
        </div>
        <div class="data-profile d-none d-md-block ">
          <div class="element-profile">
            <span>Телефон</span>
            <span class="mask-phone">{{ phone }}</span>
          </div>
          <div class="element-profile">
            <span>Электронная почта</span>
            <a :href="'mailto:'+email">{{ email }}</a>
          </div>
        </div>

        <div class="data-profile d-none d-md-block" v-if="showLastOrder">
          <div class="element-profile order-status-last">
            <div class="h6">Статус вашего последнего заказа</div>
            <div class="accent-blue">{{ lastOrder.name }}</div>
          </div>
          <div class="py-2 my-3 status-badge">
            <span v-if="lastOrder.status_id === 1">готов</span>
            <span v-else> Не готов</span>
          </div>
        </div>

        <button
            @click.prevent="edit"
            class="d-none d-md-block mt-3 btn btn-blue btn-profile"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M8.69063 20.2499H4.5C4.30109 20.2499 4.11033 20.1709 3.96967 20.0302C3.82902 19.8896 3.75 19.6988 3.75 19.4999V15.3093C3.74966 15.2119 3.76853 15.1154 3.80553 15.0253C3.84253 14.9352 3.89694 14.8533 3.96563 14.7843L15.2156 3.53429C15.2854 3.46343 15.3686 3.40715 15.4603 3.36874C15.5521 3.33033 15.6505 3.31055 15.75 3.31055C15.8495 3.31055 15.9479 3.33033 16.0397 3.36874C16.1314 3.40715 16.2146 3.46343 16.2844 3.53429L20.4656 7.71554C20.5365 7.78533 20.5928 7.86851 20.6312 7.96026C20.6696 8.052 20.6894 8.15046 20.6894 8.24992C20.6894 8.34938 20.6696 8.44784 20.6312 8.53958C20.5928 8.63132 20.5365 8.71451 20.4656 8.78429L9.21563 20.0343C9.1466 20.103 9.06469 20.1574 8.9746 20.1944C8.88452 20.2314 8.78802 20.2503 8.69063 20.2499V20.2499Z"
                stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.75 6L18 11.25" stroke="white" stroke-width="1.5" stroke-linecap="round"
                  stroke-linejoin="round"
            />
          </svg>
          Редактировать профиль
        </button>
        <button
            @click.prevent="logout"
            class="d-none d-md-block mt-3 mx-auto btn btn-link"
        >
          Выйти
        </button>
      </div>
    </div>
  </div>
  <ModalAfterAuth v-if="showHello"/>
</template>

<script>
import ModalAfterAuth from "@/partials/Modals/ModalAfterAuth";
import InputGroup from "@/elements/Input/Group";
//import router from "../../routers";
import axios from "axios";

export default {
  name: "CardProfile",
  components: {InputGroup, ModalAfterAuth},
  props: {
    mode: {
      type: String,
      default: 'show'
    }
  },
  computed: {
    showLastOrder() {
      return typeof this.$store.getters.getLastOrder === 'object';
    },

    lastOrder() {
      return this.$store.getters.getLastOrder
    }
  },
  created() {
    this.loadUserData()
    if (this.$props.mode === 'edit') {
      this.modeEdit = true
    }
  },
  methods: {
    loadUserData() {
      this.showHello = !localStorage.getItem('showHello');
      const axMainOptions = {
        url: process.env.VUE_APP_PROXY_URL + '/personal',
        method: 'POST',
        withCredentials: true,
        headers: {
          'Content-Type': "application/json"
        }
      };

      axMainOptions.data = {};

      axios(
          axMainOptions)
          .then(resp => {

            this.name = resp.data.USER_FIELDS.name
            this.email = resp.data.USER_FIELDS.email
            this.surname = resp.data.USER_FIELDS.surname
            this.phone = resp.data.USER_FIELDS.phone
            this.gender = resp.data.USER_FIELDS.gender

            this.$store.commit('setOrderList', resp.data.ORDERS_LIST)

            if (resp.data.ORDERS_LIST.length > 0) {
                this.showLastOrder = true
            }

                if(!resp.data.firstSession) {
                  localStorage.setItem('showHello', true);
                }

            if(localStorage.getItem('showHello') === 'true') {
              if(resp.data.firstSession) {
                this.showHello = true
                localStorage.setItem('showHello', true);
              }
            }
          })
          .catch()

    },

    inputHandler(e) {

      if (e[3]) {
        this.errors[e[0]] = true;
      } else {
        this.dataModel[e[0]] = e[2];
        delete this.errors[e[0]];
      }
      this.validate();

    },
    validate() {
      this.compError = Object.keys(this.errors).length > 0;
      const hasChanges = Object.keys(this.dataModel).length > 0;

      if(hasChanges) {
        this.editDisabled = false;
      }

     this.editDisabled = this.compError;

    },
    update() {
      const hasChanges = Object.keys(this.dataModel).length > 0;

      if (!this.compError && hasChanges) {

        this.$store
            .dispatch("updateUserFields", {
              name: this.dataModel.name,
              surname: this.dataModel.surname,
              phone: this.dataModel.phone,
              email: this.dataModel.email
            })
            .then(() => {
                  this.modeEdit = false;
                  this.loadUserData()
                  //router.push('/personal').then();
                }
            )
            .catch(err => console.log(err));

      } else {
          console.log("Нет изменений");
      }

    },
    edit() {
      this.modeEdit = true;
    },
    logout() {
      this.$store
          .dispatch('logout')
    }
  },
  data() {
    return {
      name: "",
      email: "",
      surname: "",
      phone: "",
      gender: "",
      showHello: false,
      editDisabled: true,
      compError: false,
      modeEdit: false,
      errors: [],
      dataModel: []
    };
  }
}
</script>

<style scoped>

</style>