<template>
  <div id="mobilePanel"
       ref="panel">
    <button
        class="btn col j-close-x"
        type="button"
        @click="close"
    >x
    </button>

    <div class="col-12 pt-5 pb-4">
      <img src="/img/service_logo.svg" alt="" class="col-12 service_logo">
    </div>
    <hr/>
    <ul class="nav mt-5">
      <li v-for="item in list" :key="item.path">
        <a :href="domain+item.path" target="_blank">
          {{ item.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "TopMobilePanel",

  methods: {
    close() {
      const hitElement = this.$refs.panel
      if (hitElement.getAttribute('class') === 'show') {
        hitElement.setAttribute('class', '')
      } else {
        hitElement.setAttribute('class', 'show')
      }
    },
  },

  mounted() {

    const tildaLinks = [
      {name: 'О компании', path: 'about'},
      {name: 'ДНК - тесты Geno.me', path: 'genome'},
      {name: 'Сотрудничество', path: 'partners_'},
      {name: 'Карьера', path: 'career'},
      {name: 'Контакты', path: 'contacts'}
    ];

    tildaLinks.forEach((route) => {

      this.list.push({
        name: route.name,
        path: route.path
      })

    })

    this.$router.options.routes.forEach(route => {
      if (route.meta.menu) {
        this.list.push({
          name: route.name,
          path: route.path
        })
      }
    })
  },
  data() {
    return {
      domain: 'https://sistemabiotech.ru/',
      list: []
    };
  },
}
</script>

<style scoped>

</style>