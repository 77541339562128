// actions/registration.js
// import axios from "axios";
//import axios from "axios";

import axios from "axios";
import router from "../../routers";

const axRegistrationOptions = {
    url: process.env.VUE_APP_REGISTER_API_URL,
    method: 'POST',
    withCredentials: true,
    headers: {
        'Access-Control-Allow-Origin': process.env.VUE_APP_REGISTER_API_URL,
        'Content-Type': "multipart/form-data",
    },

};

const actionsRegistration = {
    regCheckPhone({commit}) {
        axRegistrationOptions.data = {
            phone: this.getters.getRegistrationPhone,
            action: 'register',
            stage: 'checkPhone',
        };

        return new Promise((resolve, reject) => {

            axios(
                axRegistrationOptions)
                .then(resp => {

                    //errors
                    if (resp.data.errors) {
                        commit('SET_ERRORS', resp)
                    } else {
                        commit('setRegExist', true)
                        resolve(resp)
                    }

                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })

        })


    },

    register({commit}) {
        let localStorage = localStorage;
        axRegistrationOptions.data = this.getters.getRegistrationData;
        axRegistrationOptions.data.action = 'register';
        axRegistrationOptions.data.stage = 'default';

        return new Promise((resolve, reject) => {
            axios(axRegistrationOptions
            )
                .then(resp => {
                    console.log(resp);

                    //errors
                    if (resp.data.errors) {
                        commit('SET_ERRORS', resp)
                    } else {
                        router.push('/auth').then()
                        resolve(resp)
                    }
                })
                .catch(err => {
                    commit('auth_error', err)

                    localStorage.removeItem('token')
                    reject(err)
                })
        })
    },

}

export default actionsRegistration