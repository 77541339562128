// actions.js
import actionsAuth from '@/store/actions/auth'
import actionsPersonal from "./personal";
import actionsMft from "./mft";
import actionsRegistration from "./registration";
import actionsOrders from "./orders";
import actionsSocket from "./socket";

const index = {}

const obj = Object.assign(
    index,
    actionsAuth,
    actionsRegistration,
    actionsPersonal,
    actionsMft,
    actionsOrders,
    actionsSocket
);

export default obj
