<template>
  <FormAuth
      v-if="compScene.FormAuth"
      @event="handlerFormAuth()"
  />
  <FormCode
      v-if="compScene.FormCode" @event="handlerFormCode()"
  />

  <div class="col-12 debug" v-if="debug">
    {{this.$store.getters.getAuthData}}
  </div>

</template>

<script>

import FormCode from "@/partials/Form/Code";
import FormAuth from "@/partials/Form/Auth";

export default {
  name: 'Auth',
  components: {FormAuth, FormCode},
  props: {},
  methods: {
    handlerFormAuth() {
      this.compScene.FormAuth = false
      this.compScene.FormCode = true
    },
    handlerFormCode() {
      //Auth
      this.$store
          .dispatch("AUTH_LOGIN")
          .catch(err => console.log(err));

    }
  },
  computed: {},
  mounted() {
    this.compScene.FormAuth = true
  },
  data() {
    return {
      debug:false,
      compScene: {
        FormAuth: true,
        FormCode: false,
      }
    };
  },
}
</script>

