import {createApp} from 'vue'
import router from "./routers/"
import {store} from './store'

import App from './App'

import axios from 'axios'
import VueAxios from 'vue-axios'

//import { io } from "socket.io-client";
import Socketio from '@/plugins/Socket.io'

import Maska from 'maska'

const app = createApp({
    extends: App,
    mounted() {
        if (this.$store.getters.isLoggedIn) {

            //this.$refreshSocket.connect();
        } else {
            //this.$refreshSocket.disconnect();
        }
    },
    created() {},
    setup() {}
});

// before a request is made start the nprogress
axios.interceptors.request.use(config => {
    store
        .commit('SET_PROGRESS', {
            now: 30,
            width: '50%'
        })

    return config
})

// before a response is returned stop nprogress
axios.interceptors.response.use(response => {
    store
        .commit('SET_PROGRESS', {
            now: 100,
            width: '100%'
        })
    return response
})

axios.interceptors.request.use(req => {
    // `req` is the Axios request config, so you can modify
    // the `headers`.
    if(window.location.pathname ==='/remind') {
        req.headers.Authorization = store.getters.getTempToken;

    } else {

        req.headers.Authorization = store.getters.getAccessToken;
    }

    return req;
});


app.use(Maska);
app.use(VueAxios, axios)
app.use(store);
app.use(Socketio);
//
// app.use(Socketio, {
//     connection: process.env.VUE_APP_AUTH_SOCKET_URL,
//     options: {
//         reconnectionDelayMax: 1,
//         path: '/local/ws/token',
//         autoConnect: true,
//         transports: ["websocket"],
//         auth: {
//             token: store.getters.getRefreshToken
//         },
//         query: {}
//     }
// })




// const optionsSocket = {
//     path: '/local/ws',
//     debug: true,
//     cookie: true,
//     autoConnect: true,
//     transports: ["websocket"],
//     withCredentials: true,
//     auth: {
//         token: "123"
//     },
//     query: {
//         "my-key": "my-value"
//     }
// };
//app.use(socket)

//Options object to pass into SocketIO

//app.use(Socket, options);

// app.use(new VueSocketIO({
//     connection: 'http://biotech.local:3026/',
//     options: {
//         debug: true,
//         cookie: true,
//         autoConnect: false,
//         transports: ["polling"],
//         path: "/"
//     },
//     vuex: {
//         store,
//         actionPrefix: "SOCKET_",
//         mutationPrefix: "SOCKET_"
//     }
// }));

app.use(router).mount("#app");
