//Personal
const gettersPersonal = {
    //USER
    getPersonal: state => state.PERSONAL,
    getPersonalUpdated: state => state.PERSONAL.updated,

    getAppID: state => state.PERSONAL.APP_ID,
    getTempToken: state => state.accessTempToken,

}
export default gettersPersonal