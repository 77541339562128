<template>
  <div class="progress">
      <div class="progress-bar"
           role="progressbar"
           :style="`width: ${progress.width}`"
           :aria-valuenow="`width: ${progress.now}`"
           aria-valuemin="0"
           aria-valuemax="100"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  computed: {
    progress() {
      return this.$store.getters.getProgress
    }
  }
}
</script>


<style lang="scss">

</style>