<template>
  <TopMobilePanel/>
  <header class="d-none d-lg-flex site-header">
    <div class="container p-3">

      <div class="alert alert-danger" role="alert" v-if="errors">
        {{ this.$store.state.messages.error }}
      </div>
      <div class="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-start">
        <Logo/>
        <TopMenu/>
        <div class="col-12 col-lg-auto mb-3 mb-lg-0 me-lg-3">

        </div>
        <div class="dropdown text-end">
          <UserCircle/>
        </div>
      </div>
    </div>
  </header>
  <div class="d-lg-none header-menu site-header">
    <div class="container">
      <div class="row">
        <Hamburger
            hit="mobilePanel"
        />
        <div class="col-8 pt-3 pb-3 logotype">
          <img src="/img/logo.png" alt="logo">
        </div>
        <div class="col-2 pt-3 pb-3 lk">
          <UserCircle/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Logo from "@/elements/Logo";

import Hamburger from "@/elements/Hamburger";
import UserCircle from "@/elements/UserCircle";
import TopMenu from "./Menu/TopMenu";
import TopMobilePanel from "./Menu/TopMobilePanel";

export default {
  name: 'Header',
  components: {
    Logo, TopMenu, Hamburger, UserCircle, TopMobilePanel
  },
  methods: {
    logout() {
      this.$store
          .dispatch('logout')
    },
  },
  computed: {
    SocketMessage(){
      return this.$store.getters.getSocketMessage
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    errors() {
      return this.$store.getters.getError
    }
  },
  props: {},
  data() {
    return {};
  }
}
</script>
