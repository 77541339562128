// orders.js
const mutationsOrders = {
    setStateTube(state, payload) {
        state.stateTube = payload
    },
    setLastOrder(state, payload) {
        state.PERSONAL.LAST_ORDER = payload
    }

}

export default mutationsOrders
