<template>
  <FormCodeRemind
      v-if="compScene.FormCode"
      @event="handlerFormCode()"
  />
  <FormChangePassword
      v-if="compScene.FormChangePassword"
      @event="handlerFormChangePassword()"
  />
</template>

<script>

import router from "../routers";

import FormChangePassword from "@/partials/Form/ChangePassword";
import FormCodeRemind from "@/partials/Form/FormCodeRemind";

export default {
  name: 'Remind',
  components: {FormCodeRemind, FormChangePassword},
  props: {},
  computed: {},
  methods: {
    handlerFormChangePassword() {
      router.push('/personal').then();
    },
    handlerFormCode() {

      const loginPhone = this.$store.getters.getMFTPhone.replace(/[^+\d]/g, '');

      this.$store
          .dispatch('setAuthByPhone', loginPhone)
          .then()

      this.compScene.FormChangePassword = true
      this.compScene.FormCode = false

    }
  },
  data() {
    return {
      compScene: {
        FormCode: true,
        FormChangePassword: false
      },
      model: {
        phone: "",
        confirm_password: "",
        password: "",
        code: ""
      }
    };
  }
}
</script>

