<template>
  <section class="orders">
    <div class="container">
      <a href="/personal" class="mt-3 arrow-left">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M27 16H5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M14 7L5 16L14 25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
        <span>Заказ 50992616-0005</span>
      </a>
      <div class="row">
        <div class="col-12">


          <div class="order-card">

            <div class="order-card-head">
              <div class="order-title">
                <b class="order-name">Заказ Geno.Me PostCovid</b>
                <span class="order-code">50992616-0005</span>
              </div>
              <div class="order-price">
                <b>5 000 ₽</b>, <span class="order-status-pay"> оплачено</span>
              </div>
            </div>
            <div class="order-card-body">
              <div class="order-open">
                <div class="order-panel">
                  <div class="order-status">
                    Статус <span>Готов</span>
                  </div>

                </div>
                <div class="row order-open-description">
                  <div class="col-md-6  mb-4 order-image">
                    <img src="/img/Geno.png" alt="order">
                    <a href="#" class="mt-3 btn btn-blue-inverse download-pdf">Скачать PDF-документ</a>
                  </div>

                  <div class="col-md-6 text">
                    <h3 class="mb-4">Постковидный синдром — предупреждение и профилактика</h3>
                    <p>
                      Миллионы людей, переболевшие Covid 19, продолжают страдать от долгосрочных симптомов, которые могут длиться более 12 недель,
                      и субъективно по-разному переживаются каждым человеком. Результаты исследования помогут Вам выявить конкретные генетические
                      особенности вашего организма, играющие важную роль в общей картине возможных заболеваний, возникающих как следствие перенесенной
                      коронавирусной инфекции. Результаты теста позволят лечащему врачу дать персональные рекомендации по профилактике и снижению рисков
                      развития вторичной патологии при повторном заболевании Covid 19, а также максимально эффективно провести реабилитацию Вашего организма
                      в минимальные сроки.
                    </p>
                    <p>
                      Срок готовности результатов — 20 рабочих дней с момента поступления образца в лабораторию. Отчет исследования направляется
                      на электронную почту.
                    </p>
                    <p>
                      Результаты не имеют срока годности и будут действительны всю жизнь.
                    </p>
                  </div>

                </div>
              </div>
            </div>
          </div>



        </div>
      </div>
    </div>
  </section>

</template>

<script>

export default {
  name: 'OrderDetail',
  components: {},
  props: {},
  computed: {
    compData() {
      return this.$store.getters.getPersonal
    },
  },
  data() {
    return {

    };
  }
}
</script>
