<template>
  <section class="orders" v-if="list.length > 0">
    <div class="container">
      <div class="h3 mb-3">Заказы</div>
      <div class="row">
        <CardOrder
            v-for="item in list"
            v-bind:key="item.id"
            :element="item"
        />
      </div>
    </div>
  </section>
</template>

<script>

import CardOrder from "@/elements/Card/Order";

export default {
  name: 'OrderList',
  components: {CardOrder},
  computed: {
    list() {
      return this.$store.getters.getOrders
    }
  },
  mounted() {},
  props: {},
  data() {
    return {};
  }
}
</script>

<style lang="scss">


</style>