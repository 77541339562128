const statePersonal = {
    accessTempToken:'',
    PERSONAL: {
        updated: false,
        firstSession: false,
        USER_FIELDS: {
            name: "",
            surname: "",
            phone: "",
            email: "",
            avatar: ""
        },
        LAST_ORDER:{},
        //items id
        FAVORITE_LIST: [
            1, 2, 3, 4
        ],
        CART_LIST: [2, 3],
        ORDERS_LIST: [
            // {
            //     id: 1,
            //     guid: "",
            //     name: "",
            //     status_id: 1,
            //     cost: 999999,
            //     payment: {
            //         state_id: 1
            //     },
            //     image_src: "",
            //     desc: "",
            //     result: {
            //         file_src: ""
            //     }
            // }

        ],
        CATALOG: [
            {
                id: 1,
                head: "Geno.Me",
                name: "Geno.Me Sport",
                img_src: "",
                price: 5000,
                discount_percent: 15
            }
        ],
        BALANCE: 999
    }
}

export default statePersonal
