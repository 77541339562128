// mft.js
import axios from "axios";

const axActionOptions = {
    url: process.env.VUE_APP_PROXY_URL+'/mft',
    method: 'POST',
    withCredentials: true,
    headers: {
        'Content-Type': "application/json",
    },
};

const actionsMft = {
    MFT_CODE_SEND({commit}, payload) {

        commit('setMFTState',  false)
        commit('setMFTPhone',  payload.phone)

        payload.action = 'mft';
        payload.stage ='send';

        axActionOptions.data = payload;

        return new Promise((resolve, reject) => {

            axios(
                axActionOptions)
                .then(resp => {

                    if(resp.data.shipped) {
                        commit('setMFTStage', 'confirm')
                    }
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })

        })
    },
    MFT_CODE_CONFIRM({commit}, payload) {

        payload.action = 'mft';
        payload.stage ='confirm';

        axActionOptions.data = payload;

        return new Promise((resolve, reject) => {

            axios(
                axActionOptions)
                .then(resp => {

                    commit('setMFTState',  resp.data.confirmed)
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })

        })
    },

}

export default actionsMft