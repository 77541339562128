<template>
  <section class="d-flex box-form">
    <div class="container">
      <div class="row no-gutters">
        <div class="p-3 p-sm-5 form-biotech">
          <div class="p-3">
            <LogoEnter/>
            <div class="mb-3 mb-sm-5 title">
              <h2>Восстановление пароля</h2>
              <span>Не забудьте поменять пароль</span>
            </div>
            <div class="body-form mb-3 mb-sm-5">
              <div class="invalid-feedback d-block" v-if="passMessage">
                Пароли не совпадают
              </div>
              <InputGroup
                  label="Пароль"
                  name="password"
                  type="password"
                  placeholder="Введите пароль"
                  invalid_text="Введите пароль"
                  @event="inputHandler"
                  :view_actions="true"
              />
              <InputGroup
                  label="Пароль"
                  name="confirm_password"
                  type="password"
                  placeholder="Введите пароль"
                  invalid_text="Введите пароль"
                  @event="inputHandler"
                  :view_actions="true"
              />

            </div>
            <div class="button-panel" :data-state="compState">
              <button
                  :disabled="compError"
                  class="mx-auto mb-3 btn-form"
                  @click.prevent="setPassword">
                Сохранить
              </button>
            </div>
            <div class="col-12" v-if="debug">
              <code>{{ res }} {{compState}}<br/>{{ model }}{{ compError }}</code>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import InputGroup from "@/elements/Input/Group";
import LogoEnter from "@/elements/LogoEnter";
import axios from "axios";


export default {
  name: "FormChangePassword",
  components: {InputGroup, LogoEnter},
  emits: ["event"],
  computed: {
    compState() {
      if(this.$store.getters.getPersonalUpdated) {
        this.$emit('event', true)
        return true
      }
      return false
    }
  },
  methods: {
    inputHandler(e) {
      if (e[3]) {
        //this.errors.push(e[0]);
        this.errors[e[0]] = true;
        this.model[e[0]] = "";

      } else {
        this.model[e[0]] = e[2];
        delete this.errors[e[0]];
      }
      this.validate();
    },

    validate() {
      this.compError = Object.keys(this.errors).length > 0;

      if (this.model.password !== this.model.confirm_password) {
        this.compError = true;
        this.passIdentity = false;
      } else {
        this.passIdentity = true;
        this.passMessage = false;
      }
    },

    setPassword() {
      this.validate();
      if (!this.passIdentity) {
        this.passMessage = true;
      }
      if (!this.compError && this.passIdentity) {
        const loginPhone = this.$store.getters.getMFTPhone.replace(/[^+\d]/g, '');

        // alert(loginPhone)
        // alert( this.$store.getters.getTempToken)


        const axMainOptions = {
          url:  process.env.VUE_APP_PROXY_URL+'/personal_change',
          method: 'POST',
          withCredentials: true,
          headers: {
            'Content-Type': "application/json"
          }
        };

        axMainOptions.data = {
          login:loginPhone,
          token:this.$store.getters.getTempToken,
          password: this.model.password,
          CONFIRM_PASSWORD: this.model.confirm_password,
        };

        axios(
            axMainOptions)
            .then(resp => {
              if (resp.data.errors) {
                this.$store.commit('SET_ERRORS', resp)
              } else {

                const axMain2Options = {
                  url: process.env.VUE_APP_PROXY_URL+'/personal',
                  method: 'POST',
                  withCredentials: true,
                  headers: {
                    'Content-Type': "application/json"
                  }
                };
                axMain2Options.data = {};
                axios(
                    axMain2Options)
                    .then(resp => {

                      this.$store.commit('loadUserData', resp.data)
                      this.$store.commit('auth_success', this.$store.getters.getTempToken)

                    })
                    .catch()

              }
            })
            .catch()

      }

    },
  },
  data() {
    return {
      res: '',
      debug: false,
      compError: false,
      passIdentity: false,
      passMessage: false,
      errors: [],
      model: {
        password: "",
        confirm_password: "",
      }
    }
  },
}
</script>

