<template>
  <section class="d-flex box-form">
    <div class="container">
      <div class="row no-gutters">
        <div class="p-3 p-sm-5 form-biotech">
          <div class="p-3">
            <LogoEnter/>
            <div class="mb-3 mb-sm-5 title">
              <h2>Получение пароля</h2>
              <span>
                Введите номер телефона, мы вышлем<br/>вам SMS-сообщение
              </span>
            </div>

            <div class="body-form mb-3 mb-sm-5">
              <InputGroup
                  v-if="compStage === 'send'"
                  label="Телефон"
                  name="phone"
                  type="text"
                  placeholder=""
                  invalid_text="Введите телефон"
                  @event="inputHandler"
                  :view_actions="false"
                  :mask="'+7 (###) ###-##-##'"
              />
              <InputGroup
                  v-if="compStage === 'confirm'"
                  label="Код"
                  name="confirm_code"
                  type="text"
                  invalid_text="Введите Код"
                  @event="inputHandler"
                  :view_actions="false"
                  :mask="'####'"
              />
            </div>

            <div class="button-panel" :data-state="compState" :data-exist="exist">
              <button
                  :disabled="compError"
                  v-if="compStage === 'send'"
                  class="mx-auto mb-3 btn-form"
                  @click.prevent="sendCode">Отправить
              </button>
              <button
                  :disabled="compCodeError"
                  v-if="compStage === 'confirm'"
                  class="mx-auto mb-3 btn-form"
                  @click.prevent="confirmCode">Подтвердить
              </button>
            </div>

            <div class="col-12" v-if="debug">
              <code>{{ res }} {{ compStage }} <br/>{{ model }}<br/>compState::{{ compState }} {{ compError }}</code>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>

import InputGroup from "@/elements/Input/Group";
import LogoEnter from "@/elements/LogoEnter";

export default {
  name: "FormCodeRemind",
  components: {InputGroup, LogoEnter},
  emits: ["event"],
  mounted() {
    //TODO refactor to global
    const Recipient = this.$store.getters.getAuthData.login
    console.log(Recipient)
    if (Recipient.length > 0) {

      this.$store
          .dispatch('MFT_CODE_SEND', {
            phone: Recipient
          })

      this.model.phone = Recipient
    }

  },
  computed: {
    exist() {
      return this.$store.getters.getAuthExist
    },
    compStage() {
      return this.$store.getters.getMFTStage
    },
    compState() {
      if (this.$store.getters.getMFTState) {
        this.$emit('event', true)
        return true
      }
      return false
    }
  },
  props: {},
  methods: {
    inputHandler(e) {
      if (e[3]) {
        this.errors[e[0]] = true;
        this.model[e[0]] = "";
      } else {
        this.model[e[0]] = e[2];
        delete this.errors[e[0]];
      }
      this.validate();
    },
    validate() {
      this.compError = Object.keys(this.errors).length > 0;
      this.compCodeError = this.model.confirm_code.length !== 4;
    },
    sendCode() {
      this.$store
          .dispatch('AUTH_CHECK_PHONE', this.model.phone).then(() => {

        this.$store
            .dispatch('MFT_CODE_SEND', {
              phone: this.model.phone
            })

      })

    },
    confirmCode() {
      this.$store
          .dispatch('MFT_CODE_CONFIRM', {
            phone: this.model.phone,
            confirm_code: this.model.confirm_code
          })
    }
  },
  data() {
    return {
      res: '',
      debug: false,
      compError: true,
      compCodeError: true,
      timeLeft: 10,
      attempts: 3,
      errors: [],
      model: {
        phone: '',
        confirm_code: ''
      }
    };
  }
}
</script>

<style scoped>

</style>