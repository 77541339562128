<template>
  <div class="col-2 pt-3 pb-3 hamburger">
    <button type="button" @click.prevent="showClose">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 16H27" stroke="#1D4289" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 8H27" stroke="#1D4289" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M5 24H27" stroke="#1D4289" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  name: "Hamburger",
  props: {
    hit: String
  },
  methods: {
    showClose() {
      const hitElement = document.getElementById(
          this.$props.hit
      )
      if (hitElement.getAttribute('class') === 'show') {
          hitElement.setAttribute('class', 'd-none')
      } else {
        hitElement.setAttribute('class', 'show')
      }
    },
  },
}
</script>

<style scoped>

</style>