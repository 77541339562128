// mutations/uath.js
import router from "../../routers";

const mutationsAuth = {
    setAuthExist(state, payload) {
        state.auth_data.exist = payload
    },
    auth_success(state, token) {
        state.status = 'success'
        state.accessToken = token
        router.push('/personal').then();
    },
    logout(state) {
        state.status = ''
        state.accessToken = ''
        router.push('/auth').then()
    }
}

export default mutationsAuth
