<template>

  <ProgressBar id="ProgressBar"/>
  <Header/>
  <router-view></router-view>
  <!--  <button @click="clickButton">clickButton</button>-->
</template>

<script>

import Header from "@/partials/Header";
import ProgressBar from "@/elements/ProgressBar";

export default {
  name: 'App',
  computed: {

 },
  methods: {
    clickButton() {
      //console.log(this.$refreshSocket);
    }
  },
  setup() {
  },
  mounted() {
  },
  components: {Header, ProgressBar}
}

</script>

<style lang="scss">
@import './assets/style/app.scss';
</style>
