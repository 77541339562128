<template>
  <a href="/personal"><img src="/img/UserCircle.svg" alt="lk" /></a>
</template>

<script>
export default {
  name: "UserCircle"
}
</script>

<style scoped>

</style>