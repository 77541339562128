<template>
    <div class="modal modal-settings" v-if="show">
        <div class="container">
            <div class="box-modal">
                <div class="head-modal">
                    <h2>
                        <span>Здравствуйте!</span>
                        <br>
                        Спасибо, что воспользовались продуктами Sistema BioTech!
                    </h2>
                </div>
                <div class="body-modal">
                    <p>
                        Это Ваш личный аккаунт, где отображаются Враши покупки. Для сохранения безопасности, рекомендуем Вам сменить пароль.
                    </p>
                    <p>
                        Если вы следовали инструкции и у Вас не возникло вопросов, то ожидайте смс-уведомления о результатах Вашего ДНК-теста.
                    </p>
                    <p>
                        Еслиу Вас остались вопросы позвоните нам, и оператор подскажет дальнейшие действия., то следуйте инструкции.
                    </p>
                    <p>
                        <span>С уважением, Лаборатория Sistema BioTech.</span>
                    </p>
                    <div class="d-flex justify-content-md-between flex-wrap buttons">
                        <a href="#" target="_blank" class="btn btn-blue-inverse go-instruction mb-2">Обратиться к инструкции</a>
                        <a href="#" class="btn btn-blue ok" @click.prevent="close">Понятно</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

export default {
  name: 'ModalAfterAuth',
  components:{},
  props: {},
  computed: {},
  mounted() {},
  created(){
    //this.show = !localStorage.getItem('showHello');
  },
  methods: {
    close() {
      localStorage.setItem('showHello', false);
      this.show = false
    }
  },
  data() {
    return {
      show: true
    };
  }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .data-profile {
        display: none;
    }
    .card-profile {
        .btn-profile {
            display: none;
        }
    }

}
.modal-settings {
    display: block!important;
    background: rgb(0,0,0,0.7);
}

body {
    
    filter: blur(6px);
}

</style>
