import router from "@/routers/";
import mutationsMft from "./mft";
import mutationsRegistration from "./registration";
import mutationsAuth from "./auth";
import mutationsPersonal from "./personal";
import mutationsOrders from "./orders";
import mutationsSocket from "./socket";
// mutations.js
const index = {
        SET_PROGRESS(state, payload) {
            state.progress = payload
        },
        AUTH_REQUEST(state) {
            state.status = 'loading'
        },
        SET_AUTH_EXIST(state) {
            state.auth_data.exist = true
        },

        SET_AUTH_DATA(state, payload) {
            state.auth_data = payload
        },

        SET_ERRORS(state, payload) {
            state.errors = true
            state.messages.error = payload.data.errors[0].value;
            let timeLeft = 1;
            let interval = setInterval(() => {
                if (timeLeft === 0) {
                    clearInterval(interval)
                    state.errors = false
                    state.messages.error = '';

                } else {
                    timeLeft--
                }
            }, 1000)

        },
        SET_INPUT_ERROR(state, payload) {
            console.log("SET_INPUT_ERROR", state, payload.ob);
        },
        auth_success(state, token, user) {

            state.status = 'success'
            state.token = token
            state.user = user

            router.push('/personal').then();
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
            state.token = ''
            router.push('/auth').then(r => {
                console.log(r)
            })
        }
}

const obj = Object.assign(
    index,
    mutationsAuth,
    mutationsMft,
    mutationsRegistration,
    mutationsPersonal,
    mutationsOrders,
    mutationsSocket
);

export default obj
