// mutations/registration.js
const mutationsRegistration = {
    setRegData(state, payload) {
        state.reg_data = payload
    },
    setRegExist(state, payload) {
        state.reg_data.exist = payload
    }
}

export default mutationsRegistration
