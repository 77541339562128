<template>
  <a href="/" class="d-flex align-items-center mb-2 mb-lg-0 text-dark text-decoration-none">
    <img src="/img/logo.png" alt="" :class="SocketMessage">
  </a>
</template>

<script>
export default {
  name: "Logo",
  computed: {
    SocketMessage(){
      return this.$store.getters.getSocketMessage
    },
  },
}
</script>

<style scoped>

</style>