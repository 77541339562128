<template>
  <section class="d-flex box-form">
    <div class="container">
      <div class="row no-gutters">
        <div class="p-3 p-sm-5 form-biotech">
          <div class="p-3">
            <LogoEnter/>
            <div class="mb-3 title" v-if="view ==='full'">
              <h2>Авторизация</h2>
              <span>Вы новый пользователь? <br class="d-block d-sm-none"/><a href="/registration">Создать аккаунт</a></span>
            </div>
            <div class="mb-3 mb-sm-5" v-else>
              <h2>Авторизуйтесь</h2>
            </div>

            <div class="body-form mb-5">

              <InputGroup
                  label="Телефон"
                  name="login"
                  type="text"
                  placeholder="Телефон или почта"
                  invalid_text="Укажите логин"
                  @event="inputHandler"
                  :view_actions="false"
                  :mask="'+7 (###) ###-##-##'"
              />

              <InputGroup
                  label="Пароль"
                  name="password"
                  type="password"
                  placeholder="Введите пароль"
                  invalid_text="Введите пароль"
                  @event="inputHandler"
                  :view_actions="true"
              />

            </div>

            <div class="button-panel" :data-state="compState">

              <button
                  :disabled="compError"
                  type="button"
                  class="mx-auto mb-3 btn-form"
                  @click.prevent="doLogin">Войти
              </button>
              <a
                  href="/remind"
                  class="forgot-password"
              >Забыли пароль?
              </a>
            </div>

            <div class="col-12" v-if="debug">
              <code>{{ res }} <br/>{{ model }}  {{ compState }}</code>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>

import InputGroup from "@/elements/Input/Group";
import LogoEnter from "@/elements/LogoEnter";

export default {
  name: "FormAuth",
  components: {InputGroup, LogoEnter},
  emits: ['event'],
  props: {
    view: {
      default: 'full',
      type: String
    }
  },
  computed: {

    compState() {
      if(this.$store.getters.getAuthData.exist) {

        const loginPhone = this.model.login.replace(/[^+\d]/g, '');
        this.$store.commit('SET_AUTH_DATA', {
          login: loginPhone,
          password: this.model.password,
          exist: true
        })
        this.$emit('event')

        return true
      }
      return false
    }
  },
  methods: {
    inputHandler(e) {

      if (e[3]) {
        //this.errors.push(e[0]);
        this.errors[e[0]] = true;
        this.model[e[0]] = "";

      } else {
        this.model[e[0]] = e[2];
        delete this.errors[e[0]];
      }
      this.validate();
    },

    validate() {

      if (this.model.password.length === 0) {
        this.errors['password'] = true
      } else {
        delete this.errors['password'];
      }

      this.compError = Object.keys(this.errors).length > 0;
    },
    doLogin() {

      this.validate();

      if (!this.compError) {
        const loginPhone = this.model.login.replace(/[^+\d]/g, '');

        //Проверка пользователя
        this.$store
            .dispatch('AUTH_USER_CHECK', {
              login: loginPhone,
              password: this.model.password
            })
            .then()

      }
    },
  },
  data() {
    return {
      res: '',
      debug: false,
      compError: true,
      modeEdit: false,
      errors: [],
      model: {
        login: "",
        password: "",
      }
    };
  },
}
</script>

