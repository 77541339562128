import {createRouter, createWebHistory} from "vue-router"
//import {store} from './store/store'
import Personal from "@/pages/Personal/Personal";
import PersonalEdit from "@/pages/Personal/Edit";
import Auth from "@/pages/Auth";
import Registrartion from "@/pages/Registrartion";
import Remind from "@/pages/Remind";
import Home from "@/pages/Home";

import {store} from "@/store";

// import develop from "@/routers/develop";
// const devRoutesList = develop;

import OrderDetail from "@/pages/OrderDetail";

let routesList = [
    {
        path: "/",
        component: Home,
        name: "Домашняя страницы",
        meta: {
            title:'Личный кабинет',
            menu: false
        },
    },
    {
        path: "/auth",
        component: Auth,
        name: "Авторизация",
        meta: {
            requiresAuth: false,
            menu: false
        },

    },
    {
        path: "/registration",
        name: "Регистрация",
        component: Registrartion,
        meta: {
            requiresAuth: false,
            menu: false
        }
    },
    {
        path: "/remind",
        name: "Восстановление пароля",
        component: Remind,
        meta: {
            requiresAuth: false,
            menu: false
        }

    },
    {
        path: "/personal",
        component: Personal,
        name: "Персональный раздел",
        meta: {
            requiresAuth: true,
            menu: false
        }
    },
    {
        path: "/personal/order",
        component: OrderDetail,
        name: "Детальная заказа",
        meta: {
            requiresAuth: true,
            menu: false
        }
    },
    {
        path: "/personal/edit",
        component: PersonalEdit,
        name: "Изменить мой профиль",
        meta: {
            requiresAuth: true,
            menu: false
        }
    }
]

//routesList = routesList.concat(devRoutesList);

const router = createRouter({
    history: createWebHistory(),
    routes: routesList
})

router.beforeEach((to, from, next) => {
    //TODO Exceptions for Auth
    //console.log(to,from,next, store);
    document.title = to.name;

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isLoggedIn) {

            next()
            return
        }
        next('/auth')
    } else {
        next()
    }

})

export default router;