<template>
  <div class="col-12">
    <div class="order-card">
      <div class="order-card-head">
        <div class="order-title">

          <b class="order-name">Заказ {{ element.name }}</b>
          <span class="order-code">{{ element.guid }}</span>
        </div>
        <div class="order-price">
          <b>{{ element.cost }} ₽</b>, <span class="order-status-pay"
                                             v-if="this.$props.element.payment = 0">Не Оплачено</span>
          <span class="order-status-pay" v-if="this.$props.element.payment = 1">Оплачено</span>
        </div>
      </div>
      <div class="order-card-body" v-show="full">
        <div class="order-open">
          <div class="order-panel mb-2">
            <div class="py-2 order-status">
              Статус <span>{{ statusText }}</span>
            </div>
            <div class="order-swap">
              <button type="button" @click.prevent="swap" class="d-block btn ms-auto ">Свернуть</button>
            </div>
          </div>
          <div class="row order-open-description">
            <div class="col-12 mb-4 order-first order-md-last">
              <a v-if="this.pdf_link !== ''" :href="pdf_link"
                 target="_blank"
                 class="mt-3 btn btn-blue-inverse download-pdf">Скачать PDF-документ</a>
            </div>
            <div class="col-12 text" v-html="this.$props.element.desc">
            </div>
            <div class="col-md-4 order-image">
              <img :src="this.$props.element.image_src" alt="order" class="d-block ms-auto">
            </div>
          </div>
        </div>

      </div>

      <div class="order-card-body" v-show="short">
        <div class="d-flex order-panel">
          <div class="py-2 order-status">
            Статус <span>{{ statusText }}</span>
          </div>
        </div>
        <div class="row">
          <div class="order-swap">
            <button type="button" @click.prevent="swap" class="d-block btn ms-auto">Развернуть</button>
          </div>
          <div class="order-image ms-auto">
            <img src="/img/Geno.png" alt="order" class="d-block ms-auto">
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "CardOrder",
  props: {
    element: Object
  },
  mounted() {

    this.pdf_link = this.$props.element.pdf_link;
    this.statusText = this.statusValues[this.$props.element.status_id];

    if (this.$props.element.index > 0) {
      this.short = true
      this.full = false
    }

  },
  methods: {
    swap() {
      if (this.full) {
        this.short = true
        this.full = false
      } else {
        this.short = false
        this.full = true
      }

    }
  },
  data() {
    return {
      statusText: '',
      statusValues: ['Не Готов', 'Готов'],
      pdf_link: "",
      full: true,
      short: false
    }
  }
}
</script>
