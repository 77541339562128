<template>

  <ul class="nav top-menu col-12 col-lg-auto me-lg-auto mb-2 justify-content-center mb-md-0">
    <li v-for="item in list" :key="item.path">
      <a :href="domain+item.path" target="_blank">
        {{ item.name }}
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  name: "TopMenu",
  mounted() {

    const tildaLinks = [
      {name: 'О компании', path: 'about'},
      {name: 'ДНК - тесты Geno.me', path: 'genome'},
      {name: 'Сотрудничество', path: 'partners_'},
      {name: 'Карьера', path: 'career'},
      {name: 'Контакты', path: 'contacts'}
    ];
    tildaLinks.forEach((route) => {

      this.list.push({
        name: route.name,
        path: route.path
      })

    })


    this.$router.options.routes.forEach(route => {
      if (route.meta.menu) {
        this.list.push({
          name: route.name,
          path: route.path
        })
      }
    })
  },
  data() {
    return {
      domain: 'https://sistemabiotech.ru/',
      list: []
    };
  },
}
</script>

<style scoped>

</style>