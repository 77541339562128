<template>

  <section class="d-flex box-form">
    <div class="container">
      <div class="row no-gutters">
        <div class="form-biotech pb-3 px-5 pt-3 mt-5">
          <div class="p-3">
            <LogoEnter/>
            <div class="mb-0 title">
              <h2>Регистрация</h2>
              <span>У вас есть аккаунт?<br class="d-block d-sm-none"/><a href="/auth">Авторизоваться</a></span>
            </div>

            <div class="body-form mb-3 mb-sm-5">

              <InputGroup
                  label="Имя"
                  name="name"
                  type="text"
                  placeholder="Имя"
                  invalid_text="Введите Имя"
                  @event="inputHandler"
                  :view_actions="false"
              />

              <InputGroup
                  label="Фамилия"
                  name="surname"
                  type="text"
                  placeholder="Фамилия"
                  invalid_text="Укажите Фамилия"
                  @event="inputHandler"
                  :view_actions="false"
              />

              <div class="input-group mb-1 input-group-radio">
                <label class="form-label mt-3"><span>Пол</span></label>
                <div class="form-check me-4">
                  <label class="form-check-label" for="genderM">
                    Мужской
                    <input class="form-check-input"
                           value="М"
                           @change="radioHandler"
                           type="radio"
                           name="gender"
                           id="genderM"
                    >
                  </label>
                </div>
                <div class="form-check">
                  <label class="form-check-label" for="genderF">
                    Женский
                    <input class="form-check-input"
                           value="F"
                           @change="radioHandler"
                           type="radio"
                           name="gender"
                           id="genderF"
                    >
                  </label>
                </div>
              </div>

              <InputGroup
                  label="Телефон"
                  name="phone"
                  type="phone"
                  :default_value="this.$store.getters.getMFTPhone"
                  placeholder="Телефон"
                  invalid_text="Телефон"
                  @event="inputHandler"
                  :view_actions="true"
                  :mask="'+7 (###) ###-##-##'"
              />

              <InputGroup
                  label="Электронная почта"
                  name="email"
                  type="email"
                  placeholder="Электронная почта"
                  invalid_text="Укажите электронную почту"
                  @event="inputHandler"
                  :view_actions="false"
              />

              <InputGroup
                  label="Пароль"
                  name="password"
                  type="password"
                  placeholder="Введите пароль"
                  invalid_text="Введите пароль"
                  @event="inputHandler"
                  :view_actions="true"
              />

              <InputGroup
                  label="Повторите пароль"
                  name="confirm_password"
                  type="password"
                  placeholder="Введите пароль"
                  invalid_text="Введите пароль"
                  @event="inputHandler"
                  :view_actions="true"
              />

            </div>
            <div class="text-center mb-3 invalid-feedback d-block" v-if="passMessage">
              Пароли не совпадают
            </div>
            <div class="button-panel" :data-state="compState">
              <div class="form-check mb-4">
                <input class="form-check-input mt-2" type="checkbox" disabled="disabled" value="" id=""
                       checked="checked">
                <label class="form-check-label pt-0" for="">
                  <small>Даю согласие на обработку персональных данных</small>
                </label>
              </div>
              <button
                  :disabled="compError"
                  class="mx-auto mb-3 px-3 btn-form"
                  @click.prevent="register">Следующий шаг
              </button>
            </div>

            <div class="col-12" v-if="debug">
              <code>{{ this.$store.getters.getRegistrationData }}
                <br/>{{ res }} <br/>{{ compState }}{{ compError }}</code>
            </div>

          </div>
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import InputGroup from "@/elements/Input/Group";
import LogoEnter from "@/elements/LogoEnter";

export default {
  name: "FormRegister",
  components: {InputGroup, LogoEnter},
  mounted() {
    this.model.phone = this.$store.getters.getMFTPhone
    this.errors['gender'] = true
  },
  computed: {
    compState() {

      if (this.$store.getters.getRegistrationExist) {
        this.$store
            .dispatch('register')
        return true
      }
      return false
    }
  },
  methods: {
    inputHandler(e) {
      if (e[3]) {
        //this.errors.push(e[0]);
        this.errors[e[0]] = true;
        this.model[e[0]] = "";

      } else {
        this.model[e[0]] = e[2];
        delete this.errors[e[0]];
      }

      this.validate();
    },
    radioHandler(e) {
      //alert(e.target.value)
      this.model.gender = e.target.value;
      this.validate();
      //console.log(e.target.value)

    },
    register() {
      const _ = this;
      this.validate();

      if (!this.compError && this.passIdentity) {

        this.$store
            .commit('setRegData', {
              login: _.model.phone,
              name: _.model.name,
              surname: _.model.surname,
              gender: _.model.gender,
              email: _.model.email,
              phone: _.model.phone,
              password: _.model.password
            })

        this.$store
            .dispatch('regCheckPhone').then()

      }

    },
    validate() {

      if (this.model.password.length === 0 || this.model.confirm_password.length === 0) {
        this.errors['password'] = true
      } else {
        delete this.errors['password'];
      }

      if (this.model.confirm_password.length === 0) {
        this.errors['confirm_password'] = true
      } else {
        delete this.errors['confirm_password'];
      }

      if (this.model.gender.length === 0) {
        this.errors['gender'] = true
      } else {
        delete this.errors['gender'];
      }

      this.compError = Object.keys(this.errors).length > 0;

      if (this.model.password !== this.model.confirm_password) {
        this.compError = true;
        this.passIdentity = false;
      } else {
        this.passIdentity = true;
        this.passMessage = false;
      }

      if (!this.passIdentity) {
        this.passMessage = true;
      }

    }
  },
  data() {
    return {
      res: '',
      debug: false,
      compError: true,
      passIdentity: false,
      passMessage: false,
      errors: [],
      model: {
        login: '',
        name: '',
        surname: '',
        gender: '',
        email: "",
        phone: '',
        password: '',
        confirm_password: ''
      }
    };
  }
}
</script>

<style scoped>

</style>