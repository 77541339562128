<template>
  <div class="header-frame">
    <div class="container logo-service">
      <img src="/img/service_logo.svg" alt=""/>
    </div>
  </div>
  <section class="profile-section">
    <div class="container">
      <div class="row">
        <div class="col-md-5 col-xl-4">
          <CardProfile/>
        </div>
        <div class="row d-flex mobile-middle-menu mt-4 d-md-none">
          <a href="/personal/edit" class="col-6 mb-2 active">Мой профиль</a>
          <a href="#" class="col-6 mb-2 ">Geno.Бонусы</a>
          <a href="#" class="col-6 mb-2 ">Избранное</a>
          <a href="#" class="col-6  mb-2" @click.prevent="logout">Выйти</a>
        </div>
        <div class="col-md-7 col-xl-8 pt-0 pt-md-4 pt-lg-5">
          <FormOrderTube class="mt-auto"/>
          <OrderList/>
        </div>
      </div>
    </div>
  </section>

</template>

<script>

import OrderList from "@/elements/OrderList";
import CardProfile from "@/partials/Card/Profile";

import FormOrderTube from "@/partials/Form/OrderTube";

export default {
  name: 'PersonalPage',
  components: {OrderList, CardProfile, FormOrderTube},
  props: {},
  mounted() {},
  methods: {
    logout() {
      this.$store
          .dispatch('logout')
    }
  },
  computed: {
    PersonalData() {
      return this.$store.getters.getPersonal
    }
  },
  data() {
    return {


    };
  }
}
</script>

<style lang="scss" scoped>
.scroll-wrap {
  overflow-x: scroll;

  .mobile-middle-menu {
    min-width: 450px;

  }
}

@media (max-width: 768px) {
  .data-profile {
    display: none;
  }

  .card-profile {
    .btn-profile {
      display: none;
    }
  }
}
</style>
