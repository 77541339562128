import {createStore} from 'vuex'

import actions from '@/store/actions'
import mutations from '@/store/mutations'
import getters from '@/store/getters'
import states from '@/store/states'

export const store = createStore({
    state: states,
    mutations: mutations,
    actions: actions,
    getters: getters
});