// mutations.js
const mutationsMft = {
    setMFTStage(state, payload) {
        state.MFT_STAGE = payload
    },
    setMFTState(state, payload) {
        state.MFT_STATE = payload
    },
    setMFTCode(state, payload) {
        state.MFT_CODE = payload
    },
    setMFTPhone(state, payload) {
        state.MFT_PHONE = payload
    }
}

export default mutationsMft
