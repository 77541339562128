// actions/orders.js
import axios from "axios";

const axOrderOptions = {
    url: process.env.VUE_APP_PROXY_URL+'/orders',
    method: 'POST',
    withCredentials: true,
    headers: {
        'Content-Type': "application/json",
    },
};

const actionsOrders = {

    addTube({commit}, payload) {

        commit('setStateTube', {tube: '', added: false})

        axOrderOptions.data = {
            tube: payload.code,
            action: 'addTube'
        };
        //console.log(axOrderOptions.data);

        return new Promise((resolve, reject) => {

            axios(
                axOrderOptions)
                .then(resp => {
                    //errors
                    if (resp.data.errors) {
                        commit('SET_ERRORS', resp)
                    } else {
                        //console.log(resp);
                        //commit('setStateTube', {tube: payload, added: true})
                        commit('loadUserData', resp.data)
                        commit('setOrderList', resp.data.ORDERS_LIST)

                    }

                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })

        })

    }

}

export default actionsOrders