<template>
  <button type="button" class="btn reset"
          @click.prevent="$emit('event', 'toggle')">
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.5 3.5L3.5 12.5" stroke="#323232" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.5 12.5L3.5 3.5" stroke="#323232" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </button>
</template>

<script>

export default {
  name: "BtnReset",
  methods: {}
}
</script>