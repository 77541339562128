import stateMFT from "./mft";
import statePersonal from "./personal";
import stateOrders from "./orders";

const stateApp = {
    errors: false,
    status: '',
    token: localStorage.getItem('token') || '',
    user: localStorage.getItem('user') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    accessToken: localStorage.getItem('accessToken') || '',
    auth_data: {
        login: '',
        password: '',
        exist: false
    },
    reg_data: {
        login: '',
        name: '',
        surname: '',
        email: "",
        phone: '',
        action:'',
        exist: false
    },
    progress: {
        now: '0%',
        width: '0%'
    },
    messages: {
        error: '',
        alert: '',
        warning: ''
    }
}

const obj = Object.assign(stateApp, stateMFT, statePersonal, stateOrders);

export default obj