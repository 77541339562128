import gettersMft from "./mft";
import gettersAuth from "./auth";
import gettersRegistration from "./registration";
import gettersPersonal from "./personal";
import gettersOrders from "./orders";

const getters = {
    getProgress: state => state.progress,
    getError: state => state.errors,
    isLoggedIn: state => !!state.accessToken,
    authStatus: state => state.status,
    isLoggedLogin: state => state.user,
    getAccessToken: state => state.accessToken,
    getRefreshToken: state => state.refreshToken,
    getSocketMessage: state =>state.socket_mesage
}

const obj = Object.assign(
    getters,
    gettersMft,
    gettersAuth,
    gettersRegistration,
    gettersPersonal,
    gettersOrders
);

export default obj