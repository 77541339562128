<template>
  <FormCode
      v-if="compScene.FormCode"
      @event="handlerFormCode()"
  />
  <FormRegister
      v-if="compScene.FormRegister"
      @event="handlerFormRegister()"
  />
</template>

<script>

//import router from "../routers";

import FormCode from "@/partials/Form/Code";
import FormRegister from "@/partials/Form/Register";


export default {
  name: 'AuthPrimaryUser',
  components: {FormCode, FormRegister},
  props: {},
  methods: {
    handlerFormCode() {
      this.compScene.FormRegister = true
      this.compScene.FormCode = false
    },
    handlerFormRegister() {
      // router.push('/auth').then()
    }
  },
  computed: {},
  mounted() {
  },
  data() {
    return {
      compScene: {
        FormCode: true,
        FormRegister: false,
      }
    };
  },
}
</script>

