<template>
  <div class="mb-4 logotype">
    <img src="/img/logo_enter.png" alt="logo">
  </div>
</template>

<script>
export default {
  name: "LogoEnter"
}
</script>

<style scoped>

</style>