// personal.js
import axios from "axios";
import {store} from "@/store";


const axPersonalOptions = {
    url: process.env.VUE_APP_PROXY_URL + '/personal_update',
    method: 'POST',
    withCredentials: true,
    headers: {
        'Content-Type': "application/json"
    }
};

const actionsPersonal = {


    setPassword({commit}, user) {//{user}
        commit('setPersonalUpdated', false)
        user.action = 'updatePassword';
        axPersonalOptions.data = user;
        axPersonalOptions.url = process.env.VUE_APP_PROXY_URL + '/personal_change';
        axPersonalOptions.headers.Authorization = store.getters.getTempToken;

        return new Promise((resolve, reject) => {

            axios(
                axPersonalOptions)
                .then(resp => {

                    if (resp.data.errors) {
                        commit('SET_ERRORS', resp)
                        resolve(resp)
                    } else {
                        commit('setPersonalUpdated', true)
                    }
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })

        })

    },
    // updateUserFields() {//{user}
    //     let res = [];
    //     //TODO::with axios
    //     //user.action = 'updateUserFields';
    //     res.push({updated: true});
    //     return res;
    // },
    updateUserFields({commit}, user) {
        axPersonalOptions.data = user;

        return new Promise((resolve, reject) => {
            axios(
                axPersonalOptions)
                .then(resp => {

                    if (resp.data.errors) {
                        commit('SET_ERRORS', resp)
                    } else {

                        commit('loadUserData', resp.data)
                        resolve(resp)
                    }
                    //errors
                })
                .catch(err => {
                    reject(err)
                })


        })


    }
}

export default actionsPersonal