<template>
  <div class="input-group mb-3 input-group-cyan"
       v-bind:class="{ error: error }"
  >
    <label class="form-label mt-3">{{ label }}</label>
    <input
        v-maska = 'mask'
        autocomplete="off"
        ref="input"
        class="form-control px-0"
        v-model="compState.value"
        @click.prevent="raise('click')"
        @change.prevent="raise('change')"
        @keyup.prevent="raise('keyup')"
        :type="type"
        :name="name"
        :placeholder="placeholder"
        :disabled="disabled"
    />

    <BtnReset
        @event="clear"
        v-if="!view_actions"
    />
    <BtnViewPass @event="toggle" v-else-if="!disabled"/>

  </div>
  <div class="invalid-feedback d-block" v-if="error">
    {{invalid_text}}
  </div>
</template>

<script>

import BtnReset from "@/elements/Button/Reset";
import BtnViewPass from "@/elements/Button/ViewPass";

import useValidate from '@vuelidate/core'
import {required} from '@vuelidate/validators'
import {computed, reactive} from "vue";

export default {
  name: "InputGroup",
  components:{BtnReset, BtnViewPass},
  emits: ["event"],
  setup() {
    const compState = reactive({
      value: ""
    })
    const rules = computed(() => {
      return {
        value: { required }
      }
    })
    const v$ = useValidate(rules, compState)
    return { compState, v$ }
  },

  methods:{
    raise (event_name){

      this.v$.$validate()
      this.error = this.v$.$error

      this.$emit('event',
          [
            this.$props.name, event_name,
            this.compState.value,
            this.error
          ]
      );

    },
    clear(){
      this.compState.value = '';
      this.raise (this.$props.name,'clear', '');
    },
    toggle(){
      if(this.$refs.input.type === 'password'){
        this.$refs.input.type = 'text';
      } else{
        this.$refs.input.type = 'password';
      }
    }
  },
  props: {
    default_value:String,
    label:String,
    name:String,
    type:String,
    placeholder:String,
    btn_clear:String,
    view_actions: Boolean,
    invalid_text:String,
    mask:String
  },
  mounted() {
    this.inpmask = this.$props.mask;
    const defValue = this.$props.default_value;
    if(defValue && defValue.length > 0) {
      this.compState.value = this.$props.default_value
      this.disabled = true
    }

    //alert(this.$props.default_value)

  },
  data() {
    return {
      disabled: false,
      inpmask:[],
      error: false,
    }
  }
}
</script>
