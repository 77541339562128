// actions.js
import axios from "axios";

const axAuthOptions = {
    url: process.env.VUE_APP_AUTH_PROXY_URL+'/login',
    method: 'POST',
    withCredentials: true,
    headers: {
        'Content-Type': "application/json",
    },
};


const actionsAuth = {
    setAuthByPhone({commit}, phone) {
        //let user = this.getters.getAuthData;

        let user = {};
        user.action = 'loginByPhone';
        user.login = phone;
        axAuthOptions.url = process.env.VUE_APP_AUTH_PROXY_URL+'/login_by_phone';
        axAuthOptions.data = user;

        return new Promise((resolve, reject) => {
            commit('AUTH_REQUEST')
            axios(
                axAuthOptions)
                .then(resp => {

                    if (resp.data.errors) {
                        commit('SET_ERRORS', resp)
                    } else {

                        localStorage.setItem('accessToken', resp.data.accessToken)
                        localStorage.setItem('refreshToken', resp.data.refreshToken)

                        commit('setTempToken', resp.data.accessToken)


                        //TODO
                        // const axMainOptions = {
                        //     url: process.env.VUE_APP_PROXY_URL+'/personal',
                        //     method: 'POST',
                        //     withCredentials: true,
                        //     headers: {
                        //         'Content-Type': "application/json"
                        //     }
                        // };
                        //
                        // axMainOptions.data = {};
                        //
                        // axios(
                        //     axMainOptions)
                        //     .then(resp => {
                        //         commit('loadUserData', resp.data)
                        //
                        //     })
                        //     .catch()



                        //commit('auth_success', resp.data.accessToken)

                        resolve(resp)
                    }

                })
                .catch(err => {
                    commit('auth_error')
                    //TODO localStorage
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                    reject(err)
                })
        })

    },
    AUTH_USER_CHECK({commit}, payload) {

        commit('setAuthExist', false);
        axAuthOptions.url = process.env.VUE_APP_AUTH_PROXY_URL+'/check'
        payload.action = 'check';
        axAuthOptions.data = payload;

        return new Promise((resolve, reject) => {
            axios(
                axAuthOptions)
                .then(resp => {

                    //errors
                    if (resp.data.errors) {
                        commit('SET_ERRORS', resp)
                    } else {
                        commit('setAuthExist', true);
                        resolve(resp)
                    }
                })
                .catch(err => {
                    console.log(err);
                    reject(err)
                })

        })

    },
    AUTH_LOGIN({commit}) {

        let user = this.getters.getAuthData;
        user.action = 'login';
        axAuthOptions.data = user;
        axAuthOptions.url = process.env.VUE_APP_AUTH_PROXY_URL+'/login'

        return new Promise((resolve, reject) => {
            commit('AUTH_REQUEST')
            axios(
                axAuthOptions)
                .then(resp => {

                    if (resp.data.errors) {
                        commit('SET_ERRORS', resp)
                    } else {

                        localStorage.setItem('accessToken', resp.data.accessToken)
                        localStorage.setItem('refreshToken', resp.data.refreshToken)

                        commit('auth_success', resp.data.accessToken)

                        resolve(resp)
                    }

                })
                .catch(err => {
                    commit('auth_error')
                    //TODO localStorage
                    localStorage.removeItem('accessToken')
                    localStorage.removeItem('refreshToken')
                    reject(err)
                })
        })
    },
    AUTH_CHECK_PHONE({commit}, payload) {

        const axRegistrationOptions = {
            url: process.env.VUE_APP_REGISTER_API_URL,
            method: 'POST',
            withCredentials: true,
            headers: {
                'Access-Control-Allow-Origin': process.env.VUE_APP_REGISTER_API_URL,
                'Content-Type': "multipart/form-data",
            },
        };


        axRegistrationOptions.data = {
            phone: payload,
            action: 'check'
        };

        return new Promise((resolve, reject) => {

            axios(
                axRegistrationOptions)
                .then(resp => {
                    //errors
                    if (resp.data.errors) {
                        commit('SET_ERRORS', resp)
                    } else {

                        console.log(resp);
                        //
                        // store
                        //     .dispatch('MFT_CODE_SEND', {
                        //       phone: this.model.phone
                        //     })


                        commit('setAuthExist', true);
                        resolve(resp)
                    }

                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })

        })


    },
    logout({commit}) {
        return new Promise((resolve) => {
            commit('logout')

            //TODO localStorage
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            localStorage.removeItem('showHello');
            delete axios.defaults.headers.common['Authorization']
            window.location.href = '/auth';

            resolve()
        })
    }

}

export default actionsAuth