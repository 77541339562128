<template>
  <section class="profile-section">
    <div class="container">
      <div class="row">
        <div class="col-12">
            <ArrowBack/>
            <CardProfile
                mode="edit"
                :fields="compData.USER_FIELDS"
            />
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import ArrowBack from "../../elements/ArrowBack";
import CardProfile from "@/partials/Card/Profile";

export default {
  name: 'PersonalEdit',
  components:{ArrowBack, CardProfile},
  computed: {
    compData() {
      return this.$store.getters.getPersonal
    },
  },
  props: {},
  data() {
    return {};
  }
}
</script>

<style scoped>

.profile-section {
  z-index: 9999;
  position: relative;
  background: #F9F9F9;
  margin: 0!important
}

.card-profile {
  border: none;
}

@media (max-width: 768px){
  .card-profile {
    padding: 0 0 40px 0;
  }
}

</style>