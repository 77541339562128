<template>
  <a href="/personal" class="mt-3 arrow-left">
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27 16H5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M14 7L5 16L14 25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    <span >Назад</span>
  </a>
</template>

<script>
export default {
  name: "ArrowBack"
}
</script>

<style scoped>

</style>