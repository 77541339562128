<template>
  <div class="cover">
    <div class="cover-container d-flex w-100 h-100 p-3 mx-auto flex-column">
      <img src="/img/form_logo.svg" class="col-12 mt-auto mb-2"  alt=""/>
      <hr class="col-10 text-white-50 mx-auto" />
      <div class="h1 text-white-50 text-center">Личный кабинет
        <div class="h2">
          <a href="/personal" class="btn btn-link btn-link-white">Войти</a>
        </div>
      </div>
      <div class="mt-auto text-white-50">

      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {},
  props: {},
  mounted() {
    console.log();
  },
  data() {
    return {};
  }
}
</script>

<style scoped>
body {
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
}


.nav-masthead .nav-link {
  padding: .25rem 0;
  font-weight: 700;
  color: rgba(255, 255, 255, .5);
  background-color: transparent;
  border-bottom: .25rem solid transparent;
}

.nav-masthead .nav-link:hover,
.nav-masthead .nav-link:focus {
  border-bottom-color: rgba(255, 255, 255, .25);
}

.nav-masthead .nav-link + .nav-link {
  margin-left: 1rem;
}

.nav-masthead .active {
  color: #fff;
  border-bottom-color: #fff;
}

.cover {
  background-color: #418FDE;
  height: 100vh
}
.cover-container {
  max-width: 60em;
}

hr {
  border-top: 3px solid #FFFFFF;
}

.btn-link-white {
  font-size: 19px;
  color: #ffffff;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;

}

.btn-link-white:hover {
  opacity:0.8;
  color: #ffffff;
}

</style>
