import {io} from 'socket.io-client'
import {store} from "@/store";

export default {
    install: (app) => {
        //for logged
        const refreshSocket = io(
            process.env.VUE_APP_AUTH_SOCKET_URL,
            {
                reconnectionDelayMax: 1,
                path: '/local/ws/token',
                autoConnect: true,
                transports: ["websocket"],
                auth: {
                    token: store.getters.getRefreshToken
                },
                query: {}
            }
        )

        app.config.globalProperties.$refreshSocket = refreshSocket
        app.provide('refreshSocket', refreshSocket)

        setInterval(function() {
            refreshSocket.emit('ping');
        }, 3000);

        //emits
        refreshSocket.on('SOCKET_MESSAGE', payload => {
            store.commit('setSocketMessage', payload.message)
        })

        refreshSocket.on('SOCKET_TEST', payload => {
            if (!payload.valid) {
                localStorage.setItem('accessToken', payload.accessToken)
                localStorage.setItem('refreshToken', payload.refreshToken)
            }
        })

    }
}
